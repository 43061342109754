<template>
    <b-overlay :show="formShow" rounded="sm" no-fade>
        <center v-show="userDataInfo != null" style="font-size: 1.3em;" class="mt-2">
            Hello <strong>{{ userDataInfo ? userDataInfo.name : "N/A" }}</strong
            >, welcome to <strong>{{ userDataInfo ? userDataInfo.company.name : "N/A" }}</strong
            >'s Client Portal.
        </center>

        <b-card class="mt-2">
            <b-card-body>
                <p>
                    Thank you for visiting our client portal for managing your projects, invoices, and quotes. Our app provides an easy-to-use interface that allows you to access and store your files.
                </p>
                <p>
                    To get started, simply navigate to the Projects, Invoices, or Quotes module in the sidebar. From there, you can access the files of your company or upload any relevant files. If
                    you need to remove a file, simply right click to use the delete button next to the file in question.
                </p>

                <p>
                    If you'd like to navigate to your company's dashboard, you can use the Marketing Dashboard shortcut in the sidebar.
                </p>

                <p>
                    If you have any questions or encounter any issues while using our app, please don't hesitate to contact us for assistance. We're always here to help!
                </p>
            </b-card-body>
        </b-card>
        
        <b-card class="mt-2" v-if="timeLineArray.length > 0">
            <app-timeline>
                <app-timeline-item v-for="item in timeLineArray" :key="item.id" :title="item.title" subtitle="" :time="dateFormat(item.updated_at)" :variant="variantColor(item.status)" />
            </app-timeline>
        </b-card>

       
    </b-overlay>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink } from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";
import moment from "moment";
import homeStoreModule from "./homeStoreModule";
import store from "@/store";
import { ref, onUnmounted, reactive } from "@vue/composition-api";
import useJwt from "@/auth/jwt/useJwt";
import router from "@/router";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
    components: {
        BLink,
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,

        AppTimeline,
        AppTimelineItem,
    },

    setup() {
        const HOME_APP_STORE_MODULE_NAME = "home";
        // Register module
        if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.registerModule(HOME_APP_STORE_MODULE_NAME, homeStoreModule);
        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
        });
    },

    created() {
        this.checkUser();
    },

    data() {
        const dateNow = moment(new Date()).format("YYYY-MM-DD");

        return {
            dateNow,
            timeLineArray: [],
            formShow: false,
            userDataInfo: null,
        };
    },

    methods: {
        kFormatter,

        dateFormat(val) {
            return moment(val).format("DD-MM-YYYYY HH:mm");
        },

        variantColor(val) {
            switch (val) {
                case "A":
                    return "success";
                    break;

                case "B":
                    return "danger";
                    break;

                case "C":
                    return "primary";
                    break;

                default:
                    break;
            }
        },

        checkUser() {
            this.formShow = true;

            this.userId = parseInt(this.userData.id);

            store
                .dispatch("home/fetchUser", { id: this.userId })
                .then((response) => {
                    if (response.data.role != "supermanager") {
                        this.userDataInfo = response.data;
                    }

                    if (response.data.status != "A") {
                        // Remove userData from localStorage

                        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
                        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);

                        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

                        // Remove userData from localStorage
                        localStorage.removeItem("userData");

                        // Redirect to login page
                        router.push({ name: "auth-login" });
                    } else {
                        this.getTimeline();
                    }
                })
                .catch((error) => {});
        },

        getTimeline() {
            store
                .dispatch("home/getTimeline", { id: this.userId })
                .then((response) => {
                    this.timeLineArray = response.data;
                    this.formShow = false;
                })
                .catch((error) => {});
        },
    },
};
</script>

<style></style>
